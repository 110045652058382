import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import Title from "./Title"
import { memo, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`

const ContentContainer = styled.div`
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`
const Subtitle = styled.p`
    width: 50%;
    text-align: center;
    line-height: 30px;
    font-family: 'Roboto', 'Montserrat', sans-serif;
    color: #2d2d2d;

    @media ${device.lg} {
        width: 80%;
    }

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
        line-height: 25px;
    }

    @media ${device.xs} {
        line-height: 20px;
        font-size: 10px;
    }
`


const InfoContainer = styled.div`
    margin: 100px 0 200px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media ${device.lg} {
        width: 90%;
        margin: 100px 0 150px;
    }

    @media ${device.md} {
        margin: 100px 0 120px;
        
    }

    @media ${device.sm} {
        margin: 70px 0 100px;
    }

`

const Card = styled.div`
    width: 120px;
    height: 120px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;

    @media ${device.lg} {
        width: 100px;
        height: 100px;
    }

    @media ${device.md} {
        width: 70px;
        height: 70px;
    }

    @media ${device.sm} {
        width: 40px;
        height: 40px;
    }

    @media ${device.xs} {
        width: 35px;
        height: 35px;
    }
`


const CardRehau = styled(Card)`
    width: 210px;
    height: 210px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;

    @media ${device.lg} {
        width: 180px;
        height: 180px;
    }

    @media ${device.md} {
        width: 130px;
        height: 130px;
    }

    @media ${device.sm} {
        width: 80px;
        height: 80px;
    }

    @media ${device.xs} {
        width: 60px;
        height: 60px;
    }
`


const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }

`

const WhoAreWeComponent = () => {

    const location = useLocation();

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])

    return (

        <Container>

            <ContentContainer>

                <Title
                    title="¿Quienes somos?"
                />

                <Subtitle className="utils__fade-in-fast-top">Somos una empresa familiar con 25 años de experiencia en el mercado.</Subtitle>

                <Subtitle className="utils__fade-in-fast-top">Tenemos como objetivo fomentar un proceso de permanente superación, mejora y desarrollo.</Subtitle>

                <Subtitle className="utils__fade-in-fast-top">Con las diferentes gamas de productos satisfacemos los requerimientos más exigentes del mercado.</Subtitle>

                <Subtitle className="utils__fade-in-fast-top">Trabajamos tanto en grandes como en pequeños proyectos y tenemos vasta experiencia en obras que
                    involucran un alto desafío técnico y logístico como edificios de altura y condominios en todo
                    el territorio nacional.
                </Subtitle>

                <InfoContainer>

                    <Card
                        key={3}
                        className="utils__fade-in-fast-right"
                        onClick={async () => {

                        }}
                    >
                        <Image src={require('../images/hydro.png')} alt='hydro' loading="lazy" />

                    </Card>

                    <Card
                        key={4}
                        className="utils__fade-in-fast-top"
                        onClick={async () => {

                        }}
                    >

                        <Image src={require('../images/aluar.png')} alt='aluar' loading="lazy" />

                    </Card>



                    <Card
                        key={5}
                        className="utils__fade-in-fast-top"
                        onClick={async () => {

                        }}
                    >

                        <Image src={require('../images/ok-industrial.png')} alt='ok-industrial' loading="lazy" />

                    </Card>

                    <Card
                        key={6}
                        className="utils__fade-in-fast-top"
                        onClick={async () => {

                        }}
                    >
                        <Image src={require('../images/gu.png')} alt='gu' loading="lazy" />

                    </Card>
                    <CardRehau
                        key={7}
                        className="utils__fade-in-fast-left"
                        onClick={async () => {

                        }}
                    >

                        <Image src={require('../images/rehau.png')} alt='rehau' loading="lazy" />

                    </CardRehau>
                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}

const WhoAreWe = memo(WhoAreWeComponent);

export default WhoAreWe;