import { memo, useState } from "react";
import AppLogoComponent from "./AppLogoComponent"
import { styled } from 'styled-components';
import { device } from "../styles/stylesConfig";
import MenuButton from "./MenuButton";
import Menu from "./Menu";
import { useAnimations } from "../effects/Effects";
import { useHistory, useLocation } from "react-router-dom";
import { useLogEvent } from "../hooks/useLogEvent";
import QuotatorButton from "./QuotatorButton";
import CountryFlags from "./CountryFlags";

const Container = styled.div`
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 3;
    box-shadow: 0px 4px 12px 0px rgba(27, 48, 100, 0.5);
    backdrop-filter: blur(0px);
    background-color: transparent;
    padding: 7.3px 0px;
    transition: all 0.75s ease-in;
    background-color: #fff;
    border-bottom: 1px solid #004bb5;

    @media ${device.lg} {
        height: 70px;
        padding: 7.3px 0px;
    }

`

const MenuDiv = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media ${device.lg} {
        width: 80%;
        height: auto;
        flex-direction: row;
    }

        @media ${device.xs} {
        width: 90%;
    }


`

const NavDiv = styled.div`
    width: 600px;
    display: flex;
    color: black;
    align-items: center;
    justify-content: space-between;
    
    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    }
`

const NavButton = styled.button`
    color: black;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 10px 5px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;

    &::before {
        content: '';
        height: 5px;
        width: 0%;
        bottom: 0;
        left: 0;
        position: absolute;
        background-color: #ff0c19;
        transition: width 0.3s ease;
    }

    &:focus,
    &:hover {
        outline: none;

        &::before {
            width: 100%;
        }

        & > div {
            visibility: visible !important;
        }
        
    }

`

export const HeaderComponent = () => {

    const [showMenu, setShowMenu] = useState(false);

    const {
        handleClickGoHome,
        handleClickGoHomeInstant,
        handleClickGoAbout,
        handleClickGoAboutInstant,
        handleClickGoProducts,
        handleClickGoProductsInstant,
        handleClickGoJobs,
        handleClickGoJobsInstant,
        handleClickGoContact,
        handleClickGoContactInstant,
        handleClickGoQuotator,
        handleClickGoQuotatorInstant,
    } = useAnimations()

    const history: any = useHistory();

    const location = useLocation();

    const { handleLogEvent } = useLogEvent();

    return (

        <Container className="utils__fade-in-fast-bottom">

            <MenuDiv>

                <AppLogoComponent />

                <CountryFlags device="mobile" />

                <CountryFlags style={{ visibility: "hidden" }} device="desktop" />

                <NavDiv>

                    <NavButton onClick={async (e: any) => {

                        if (location.pathname !== "/") {
                            await history.push('/');

                            setTimeout(() => {
                                handleClickGoHomeInstant()
                            }, 10);
                        } else {
                            handleClickGoHome();
                        }

                        handleLogEvent('click_boton_inicio_menu');

                        e.target?.blur()

                    }}>
                        Inicio
                    </NavButton>

                    <NavButton onClick={async (e: any) => {

                        if (location.pathname !== "/nosotros") {
                            await history.push('/nosotros');

                            setTimeout(() => {
                                handleClickGoAboutInstant()
                            }, 10);
                        } else {
                            handleClickGoAbout();
                        }

                        handleLogEvent('click_boton_nosotros_menu');

                        e.target?.blur()

                    }}>
                        Nosotros
                    </NavButton>

                    <NavButton onClick={async (e: any) => {

                        if (location.pathname !== "/productos") {
                            await history.push('/productos');

                            setTimeout(() => {
                                handleClickGoProductsInstant()
                            }, 10);
                        } else {
                            handleClickGoProducts();
                        }

                        handleLogEvent('click_boton_productos_menu');

                        e.target?.blur()

                    }}>
                        Productos
                        {/* <HeaderProductsList /> */}
                    </NavButton>

                    <NavButton onClick={async (e: any) => {

                        if (location.pathname !== '/obras') {

                            history.push('/obras');

                            setTimeout(() => {
                                handleClickGoJobsInstant()
                            }, 10);

                        } else {

                            handleClickGoJobs()

                        }

                        handleLogEvent('click_boton_obras_menu');

                        e.target?.blur()


                    }}>
                        Obras
                    </NavButton>

                    <NavButton onClick={async (e: any) => {



                        if (location.pathname !== '/contacto') {

                            history.push('/contacto');

                            setTimeout(() => {
                                handleClickGoContactInstant()
                            }, 10);

                        } else {

                            handleClickGoContact()

                        }

                        handleLogEvent('click_boton_contacto_menu');

                        e.target?.blur()

                    }}>
                        Contacto
                    </NavButton>

                </NavDiv>

                <CountryFlags device="desktop" />

                <QuotatorButton onClick={async () => {

                    if (location.pathname !== '/cotizador') {

                        history.push('/cotizador');

                        setTimeout(() => {
                            handleClickGoQuotatorInstant()
                        }, 10);

                    } else {

                        handleClickGoQuotator()

                    }

                    handleLogEvent('click_boton_cotizador_menu');

                }} />

                <MenuButton showMenu={showMenu} setShowMenu={setShowMenu} />

            </MenuDiv>

            <Menu showMenu={showMenu} setShowMenu={setShowMenu} />

        </Container>

    )
}

const Header = memo(HeaderComponent);

export default Header;
