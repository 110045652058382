import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { useHistory, useLocation } from "react-router-dom"
import { useAnimations } from "../effects/Effects"
import { memo } from "react"
import BrandsCarrousel from "./BrandsCarrousel"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`

const ContentContainer = styled.div`
    width: 80%;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    background-color: #111;
    padding: 50px 5px;

    @media ${device.lg} {
        grid-template-columns: repeat(1, 1fr);
    }
`

const QuotatorButton = styled.button`
    color: white;
    font-size: 16px;
    background-color: #004bb5;
    border: 1px solid white;
    box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.3);
    width: fit-content;
    padding: 15px 20px;
    cursor: pointer;
    transition: all .3s ease;
    align-self: center;
    justify-self: start;

    &:hover {
        transform: scale(1.05);
    }

    @media ${device.lg} {
        justify-self: center;
    }

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }
`

const GoHomeButton = styled.button`
    width: fit-content;
    height: fit-content;
    border-radius: 50px;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    align-self: center;
    justify-self: center; 

`


const GoHomeImage = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50px;
    padding: 15px;
    background-color: #ff0c19;
    box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: all .3s ease;
    align-self: center;
    justify-self: center;   

    &:hover {
        transform: scale(1.05);
    }

    @media ${device.md} {
        width: 30px;
        height: 30px;
    }

    @media ${device.sm} {
        width: 25px;
        height: 25px;
        padding: 15px;
    }
`

const DataContainer = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media ${device.lg} {
        flex-direction: column;
        margin: 50px 0px;
    }

`

const Info = styled.a`
    color: white;
    text-transform: uppercase;
    margin-bottom: 20px;
    cursor: pointer;
    text-align: center;
    line-height: 20px;

    &:hover {
        text-decoration: underline;
    }

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }
`

const SocialContainer = styled.div`
    width: 220px;
    display: flex;
    justify-content: center;
    /* justify-content: space-between; */ // DESCOMENTAR CUANDO SE AGREGUE LINKEDIN
    margin-top: 20px;

    @media ${device.md} {
        width: 200px;
    }
`

const SocialButton = styled.button`
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: all .3s ease;
    padding: 10px;

    &:hover {
        background-color: #004bb5;
    }
`

const SocialIcon = styled.img`
    width: 40px;
    height: 40px;

    @media ${device.md} {
        width: 35px;
        height: 35px;
    }
`

const SocialName = styled.p`
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 10px;

    @media ${device.sm} {
        font-size: 12px;
    }
`


const FooterComponent = () => {

    const history: any = useHistory()

    const location: any = useLocation()

    const { handleLogEvent } = useLogEvent();

    const {
        handleClickGoHome,
        handleClickGoQuotator,
        handleClickGoQuotatorInstant,
        handleClickGoAbout,
        handleClickGoProducts,
        handleClickGoProduct,
        handleClickGoJobs,
        handleClickGoContact
    } = useAnimations();

    return (

        <Container>

            <BrandsCarrousel />

            <ContentContainer>

                <QuotatorButton
                    className="utils__fade-in-fast-top"
                    onClick={async () => {

                        if (location.pathname !== '/cotizador') {

                            history.push('/cotizador');

                            setTimeout(() => {
                                handleClickGoQuotatorInstant()
                            }, 10);

                        } else {

                            handleClickGoQuotator()

                        }

                        handleLogEvent('click_boton_footer_cotiza_obra_proyecto');

                    }}
                >
                    COTIZA TU OBRA/PROYECTO
                </QuotatorButton>

                <DataContainer>

                    <Info
                        className="utils__fade-in-fast-top"
                        href="https://maps.app.goo.gl/wtZC4qRm4RViRnZZA"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_ubicacion');
                        }}
                    >Av. Gral Juan Gregorio Lemos 4060
                        <br />Ingeniero Adolfo Sourdeaux
                    </Info>

                    <Info
                        className="utils__fade-in-fast-top"
                        href="tel:+5491164224433"
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_primer_telefono');
                        }}
                    >+54 011 6422-4433</Info>

                    <Info
                        className="utils__fade-in-fast-top"
                        href="mailto:ventas@aluviher.com.ar"
                        target='_blank'
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_correo');
                        }}
                    >ventas@aluviher.com.ar</Info>

                    <SocialContainer>

                        <SocialButton
                            className="utils__fade-in-fast-top"
                            onClick={() => {
                                handleLogEvent('click_instagram');
                                window.open("https://www.instagram.com/aluviher_ok", "_blank");
                            }}
                        >

                            <SocialIcon src={require('../images/instagram.png')} alt="Instagram" />

                            <SocialName>Instagram</SocialName>

                        </SocialButton>

                        <SocialButton
                            className="utils__fade-in-fast-top"
                            onClick={() => {

                                handleLogEvent('click_linkedin');
                                window.open("https://www.linkedin.com/company/aluvihersrl/", "_blank")
                            }}
                        >

                            <SocialIcon src={require('../images/linkedin.png')} alt="LinkedIn" />

                            <SocialName>LinkedIn</SocialName>

                        </SocialButton>

                    </SocialContainer>

                </DataContainer>

                <GoHomeButton
                    className="utils__fade-in-fast-top"
                    onClick={() => {

                        handleLogEvent('click_boton_ir_arriba');

                        if (location.pathname === '/') {
                            handleClickGoHome();
                        } else if (location.pathname === '/nosotros') {
                            handleClickGoAbout();
                        } else if (location.pathname === '/productos') {
                            handleClickGoProducts();
                        } else if (location.pathname.includes('/productos/')) {
                            handleClickGoProduct();
                        } else if (location.pathname === '/obras') {
                            handleClickGoJobs()
                        } else if (location.pathname === '/contacto') {
                            handleClickGoContact()
                        } else if (location.pathname === '/cotizador') {
                            handleClickGoQuotator()
                        }

                    }}
                >
                    <GoHomeImage src={require('../images/flecha-blanca.png')} alt="LinkedIn" />
                </GoHomeButton>
            </ContentContainer>

        </Container>

    )
}

const Footer = memo(FooterComponent);

export default Footer;