import { Fragment, memo, useEffect, useRef, useState } from "react"
import { introImagesList } from "../utils/Jsons"
import MemorizedIntroImageItem from "./IntroImageItem";

const IntroductionComponent = () => {

    const containerRef = useRef(null);

    const [visibleImage, setVisibleImage] = useState(0);

    const [animationActive, setAnimationActive] = useState(false);

    const handleClick = () => {

        setAnimationActive(true);

        setTimeout(() => {
            setAnimationActive(false);
        }, 1500);
    };

    const cambiarImagen = (direction: any) => {

        handleClick()

        const container: any = containerRef.current;
        const totalImagenes = introImagesList.length;

        let nuevaImagenVisible = visibleImage + direction;

        if (nuevaImagenVisible === -1) {
            nuevaImagenVisible = totalImagenes - 1;
        } else if (nuevaImagenVisible < 1) {
            nuevaImagenVisible = 0;
        } else if (nuevaImagenVisible >= totalImagenes) {
            nuevaImagenVisible = 0;
        }

        const nuevaPosicion = container.children[nuevaImagenVisible].offsetLeft;
        container.scrollTo({ left: nuevaPosicion, behavior: 'smooth' });
        setVisibleImage(nuevaImagenVisible);
    };

    useEffect(() => {

        const intervalo = setInterval(() => {

            const element = document.getElementById('next');

            element?.click()

        }, 5000);

        return () => clearInterval(intervalo);
    }, []);

    return (

        <div className='introduction__container' id="home">

            <div className='introduction__scroll-container'>

                <button key={10} disabled={animationActive} className="introduction__scroll-arrow-go-left" onClick={() => cambiarImagen(-1)}>
                    <span key={11}></span>
                </button>

                <button key={12} disabled={animationActive} id="next" className="introduction__scroll-arrow-go-right" onClick={() => cambiarImagen(1)}>
                    <span key={13}></span>
                </button>

            </div>

            <div className='introduction__works-counter'>

                {
                    introImagesList.map((value, index) => (

                        <Fragment key={value.title}>

                            <button
                                className={`introduction__work-indicator ${value.id === (visibleImage + 1) ? 'introduction__work-indicator-selected' : ''}`}
                                key={value.id + ' dots' + index}
                                onClick={(e) => {

                                    const indexClicked = parseInt(e.currentTarget.textContent!) - 1;

                                    if (visibleImage !== indexClicked) {
                                        cambiarImagen(indexClicked - visibleImage);
                                    }

                                }}
                            >
                                {`0${index + 1}`}
                            </button>

                            <hr key={value.id + ' hr' + index} className='introduction__hr-counter' />

                        </Fragment>

                    ))
                }

            </div>


            <div ref={containerRef} className='introduction__content-container' id="proyects-list">

                {
                    introImagesList.map((value, index) => (

                        <MemorizedIntroImageItem item={value} key={value.id + ' images' + index} animationActive={animationActive} />

                    ))
                }

            </div>

        </div>

    )
}

const Introduction = memo(IntroductionComponent);

export default Introduction;